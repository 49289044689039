import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";
import { Row, Col } from "styled-bootstrap-grid";
import breakpoints from "../styles/breakpoints";
import Container from "./Core/Container";
import Section from "./Core/Section";

// icons
import Icon from "./Core/Icon";

const CardList = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-gap: 1.25rem;
    padding: 0;
    margin: 0;

    @media only screen and (min-width: ${breakpoints.md}) {
        grid-template-columns: 1fr 1fr;
    }

    @media only screen and (min-width: ${breakpoints.desktop}) {
        grid-template-columns: ${props => props.property ? "1fr 1fr" : "1fr 1fr 1fr"};
    }

`;

const Card = styled.li`
    min-height: 15.75rem;
    width: 100%;
    box-shadow: 0px 3px 0.2rem 0px rgb(0 0 0 / 30%);
    border-radius: .25rem;
    position: relative;

    .gatsby-image-wrapper {
        height: 23.75rem;
        width: 100%;
    }

    .property-container {
      padding: 1.25rem;
    }

    h3 {
        font-size: 1.5rem;
        margin: 0;
    }

    p {
        margin-bottom: 1.25rem;
    }
    
    .region {
        max-width: 17.188rem;
    }

    .link {
        border: none;
        background: var(--secondary);
        color: var(--white);
        cursor: pointer;
        box-sizing: border-box;
        padding: 15px 45px;
        text-align: center;
        font-weight: 600;
        border-radius: 5px;
        display: block;
        font-size: 0.875rem;
        margin: 0 auto;
        text-decoration: none;
        position: absolute;
        bottom: 1.25rem; 
        left: 50%;
        transform: translateX(-50%);
        white-space: nowrap;
        z-index: 3;

        &:hover,
        &:focus {
            background: var(--lightenButton);
        }
    }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  text-decoration: none;
  box-sizing: border-box;
  background: #fff;
  position: relative;
  font-size: 1.5rem;
  color: #000;
  
  img {
    height: 15rem;
    object-fit: cover;
  }

  .margin,
  .price {
    margin-bottom: 4.375rem;
  }
  
  .overlay-link {
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
  }
`;

const IconCard = styled.div`
    span {
       color: var(--secondary); 
    }

    .price {

        font-size: 1.15rem;

        span {
            color: #000;
            font-weight: 700;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin-bottom: 1.25rem;
        display: flex;
    }

  li {
      display: flex;
      align-items: center;
      position: relative;
      margin-right: 1.25rem;
      z-index: 3;
  }

  .icon {
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.5rem;
      background: var(--primary);
      fill: var(--white);
      border-radius: 50%;
  }
  
  .brand-image {
    height: 1.875rem;
  }
`;

const Tooltip = styled.span`
    position: absolute;
    top: -1.7rem;
    z-index: 2;
    left: 50%;
    color: #fff!important;
    text-transform: capitalize;
    font-size: .75rem;
    background: rgba(0,0,0,0.8);
    width: max-content;
    padding: 0.5rem;
    border-radius: 0.4rem;
    opacity: 0;
    transition: opacity .5s ease;
    transform: translateX(-50%);

    .icons:hover > & {
        opacity: 1;
    }

`;


const PropertyList = (props) => {

    const {properties} = props;

    function sortAmenities(arr) {

        const sortedArray=[];
            
        arr.forEach(amenity => {

            let name;
                let index;
                let icon;

            switch(true) {
                case /hot-tub|hottub|hot tub/gi.test(amenity):
                    name = "hot tub";
                    icon = "icon-hot-tub";
                    index = 1;
                    break;
                case /dog |pet|dogs/gi.test(amenity):
                    name = "pet friendly";
                    icon = "icon-pet";
                    index = 2;
                    break;
                case /wifi|wi-fi|broadband/gi.test(amenity):
                    name = "wi-fi";
                    icon = "icon-thunderbolt";
                    index = 3;
                    break;
                case /garden|secluded/gi.test(amenity):
                    name = "garden";
                    icon = "icon-flower";
                    index = 4;
                    break;
                case /parking/gi.test(amenity):
                    name = "parking";
                    icon = "icon-parking";
                    index = 5;
                    break;
                default:
                    name = amenity;
                    icon = "icon-star";
                    index = 6;
            }

            sortedArray.push({
                "name":name, 
                "index":index, 
                "icon":icon, 
            });
            
        });

       return sortedArray.sort((a,b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    }

    return (
      <Section id={props.id ? props.id : null}>
        <Container>
          <Row>
            <Col lg={12}>
              <div>
                {props.heading ? <h2>{props.heading}</h2> : ""}
                {props.tagline ?<p>{props.tagline}</p>: ""}
                <CardList property={props.property}>
                  {properties.map(function(property, index){
                        let sortedArray, link;
                        // sort amentities into popularity brackets
                        sortedArray = sortAmenities(property.facilities);
                        sortedArray = sortedArray.slice(0, 4);
                        // add correct link based on what's present in the BE

                        if (property.bookingComUrl) {
                          link = property.bookingComUrl;
                      } else if (property.slug) {
                          link = property.slug.current;
                      } else if (property.stUrl) {
                          link = property.stUrl;
                      } else if (property.parkUrl) {
                          link = property.parkUrl;
                      } else if (property.bookingUrl) {
                          link = property.bookingUrl;
                      } else if (property.hoseasonsUrl) {
                          link = property.hoseasonsUrl;
                      }
                        

                        return ( 
                          <Card key={index} className="grid-item">
                            <CardContainer>
                              <Link to={link} className="overlay-link"/>
                              <img src={property.imageUrl} alt={property.name} />
                                <IconCard className="property-container">
                                  <h3>{property.name}</h3>
                                  {property.brandImage && <img className="brand-image" src={property.brandImage.image.asset.fluid.src} />}
                                  <p>
                                    {property.region}
                                  </p>
                                  <ul>
                                    {sortedArray ? sortedArray.map(function(amenity, index){
                                      
                                      return ( 
                                        <li className="icons" key={index} title={amenity.name}>
                                          <Tooltip>{amenity.name}</Tooltip>
                                          <Icon name={amenity.icon} />
                                        </li>
                                      );
                                  }) : ""}
                                  </ul>
                                  <p>{property.description}</p>
                                  {/* <Link to={property.slug.current} className="link">View property</Link> */}
                                </IconCard>
                            </CardContainer>
                          </Card>
                        );
                    })}
                </CardList>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    );
};

export default PropertyList;